export const BRAZILIAN_STATES = <const>{
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goías',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraíma',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

export const FTD_STATES = <const>{
  SIM: 'SIM',
  NÃO: 'NÃO',
};

export const POSITION_STATES = <const>{
  Professor: 'Professor',
  Coordenador: 'Coordenador',
  Diretor: 'Diretor',
  Outro: 'Outro',
};

export const SEGMENT_STATES = <const>{
  'Educação Infantil': 'Educação Infantil',
  'Ensino Fundamental I': 'Ensino Fundamental I',
  'Ensino Fundamental II': 'Ensino Fundamental II',
  'Ensino Médio': 'Ensino Médio',
};

export const AREA_STATES = <const>{
  'Área Privada': 'Área Privada',
  'Área Pública': 'Área Pública',
};
