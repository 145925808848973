import {
  BRAZILIAN_STATES,
  POSITION_STATES,
  AREA_STATES,
  SEGMENT_STATES,
  FTD_STATES,
} from '@constants';

export const SELECT_OPTIONS = Object.entries(BRAZILIAN_STATES).map(
  ([key, _value]) => ({
    value: key,
    label: key,
  }),
);

export const AREA_OPTIONS = Object.entries(AREA_STATES).map(
  ([key, _value]) => ({
    value: key,
    label: key,
  }),
);

export const FTD_OPTIONS = Object.entries(FTD_STATES).map(([key, _value]) => ({
  value: key,
  label: key,
}));

export const POSITION_OPTIONS = Object.entries(POSITION_STATES).map(
  ([key, _value]) => ({
    value: key,
    label: key,
  }),
);

export const SEGMENT_OPTIONS = Object.entries(SEGMENT_STATES).map(
  ([key, _value]) => ({
    value: key,
    label: key,
  }),
);
